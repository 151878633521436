<template>
  <v-container
    id="search"
    fluid
    tag="section"
  >
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="colorSnak"
    >
      {{ text }}
      <v-btn
        color="black"
        text
        @click="snackbar = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
    <div class="text-center">
      <v-dialog
        v-model="dialogpp"
        width="80%"
      >
        <v-card>
          <v-card-title class="text-h2 grey lighten-2">
            Aviso de privacidad
          </v-card-title>

          <v-card-text>
            <p class="text-justify">
              IDENTIFICACIÓN Y DOMICILIO DEL RESPONSABLE. <br>
              NACOM 21 S. DE R.L. de C.V., en adelante BNI QUINTANA ROO, con domicilio ubicado en Avenida Homero 1933 piso 8 interior 801-2, Colonia Polanco I Sección, Miguel Hidalgo, Ciudad de México, Código Postal 11510, es responsable de recabar sus datos personales para los efectos enlistados en el presente aviso de privacidad, así como del uso y la protección de estos.
            </p>

            <ol class="ur">
              <li>
                <strong>DATOS PERSONALES QUE SE RECABAN</strong>

                <p>
                  Derivado de la calidad de Organización de Redes de Negocio, la información puede ser recabada de manera directa o indirecta a través de los siguientes medios:
                </p>

                <ol class="ua">
                  <li>
                    DE LOS MIEMBROS DE BNI QUINTANA ROO:
                    <ol class="la">
                      <li>
                        De forma directa:
                        <ul>
                          <li>
                            Formatos o formularios físicos.
                          </li>
                          <li>
                            Vía telefónica.
                          </li>
                          <li>
                            Correo electrónico.
                          </li>
                          <li>
                            Formularios electrónicos a través de sus páginas de internet.
                          </li>
                          <li>
                            Encuestas.
                          </li>
                          <li>
                            Entrevistas directas.
                          </li>
                          <li>
                            Aplicaciones de Internet.
                          </li>
                        </ul>
                      </li>
                      <li>
                        De forma indirecta:
                        <ul>
                          <li>
                            Intercambio de referencias o recomendaciones por parte de sus miembros, visitantes, clientes y prospectos.
                          </li>
                        </ul>
                      </li>
                    </ol>
                  </li>
                  <li>
                    DE LOS EMPLEADOS DE BNI QUINTANA ROO:
                    <ol class="la">
                      <li>
                        De forma directa:
                        <ul>
                          <li>
                            Solicitudes de empleo.
                          </li>
                          <li>
                            Currículum Vitae.
                          </li>
                        </ul>
                      </li>
                    </ol>
                  </li>
                  <li>
                    DE LOS PROVEEDORES DE BNI QUINTANA ROO:
                    <ol class="la">
                      <li>
                        De forma directa cuando dichos proveedores son miembros de BNI QUINTANA ROO.
                      </li>
                      <li>
                        De forma indirecta cuando se contratan los servicios.
                      </li>
                    </ol>
                  </li>
                  <p class="mt-2 mb-0">
                    Como se describe anteriormente, ya sea de manera directa o indirecta, y por los medios especificados, BNI QUINTANA ROO, podrá solicitar de usted la siguiente información:
                  </p>
                  <ul>
                    <li>
                      Nombre completo.
                    </li>
                    <li>
                      Domicilio particular y/o laboral.
                    </li>
                    <li>
                      Teléfono.
                    </li>
                    <li>
                      Profesión.
                    </li>
                    <li>
                      Edad.
                    </li>
                    <li>
                      Sexo.
                    </li>
                    <li>
                      Registro Federal De Contribuyentes.
                    </li>
                    <li>
                      Domicilio Fiscal.
                    </li>
                    <li>
                      Información Sobre El Medio De Pago.
                    </li>
                    <li>
                      Correo electrónico.
                    </li>
                    <li>
                      Información relacionada con su actividad comercial, como puede ser: Nombre de su empresa o negocio, antigüedad en la empresa o negocio, dirección, teléfonos, correo electrónico, clientes o proveedores recientes.
                    </li>
                    <li>
                      Habilidades personales.
                    </li>
                    <li>
                      Gustos.
                    </li>
                    <li>
                      Pasatiempos.
                    </li>
                    <li>
                      Estado civil.
                    </li>
                    <li>
                      Pertenencia a otras organizaciones de cualquier tipo.
                    </li>
                    <li>
                      Antecedentes laborales.
                    </li>
                  </ul>
                  <p class="mt-2">
                    BNI QUINTANA ROO, recaba datos personales considerados como sensibles por la Ley Federal De Protección De Datos Personales En Posesión De Los Particulares, consistentes en el estado de salud, BNI QUINTANA ROO única y exclusivamente cuando en el caso de los miembros de BNI QUINTANA ROO, éstos comuniquen a la empresa su interés en participar en alguno de los Congresos que BNI QUINTANA ROO organiza con motivo de su actividad y en los cuales es necesario conocer dicha información. Y en el caso de los empleados, cuando por razón del desempeño de su empleo, igualmente deban acudir a los eventos que la empresa organiza, lo anterior a fin de salvaguardar la salud del resto de todos los asistentes a dichos eventos.
                  </p>
                </ol>
              </li>
              <li>
                <strong>FINALIDADES DEL TRATAMIENTO DE DATOS PERSONALES</strong>
                <p>
                  A través del presente Aviso de Privacidad BNI QUINTANA ROO, declara que las finalidades del tratamiento de tus Datos Personales serán los siguientes:
                </p>
                <p>
                  En el caso de los miembros de BNI QUINTANA ROO:
                </p>
                <ul>
                  <li class="mb-2">
                    Será utilizada para proporcionarle la información que requiere para formar parte de la organización, y para que en caso de que decida formar parte de BNI QUINTANA ROO se le pueda otorgar la calidad de miembro y proporcionarle todos los servicios y beneficios de unirse a la organización de empresarios más grande a nivel mundial, organizar los capítulos bajo los cuales opera la organización, así como comunicarle cambios y actualizaciones básicos dentro de la organización, capacitación, captar referencias de negocios e invitarlo a participar en los eventos que BNI QUINTANA ROO lleva a cabo como parte de la finalidad de la organización, así como comunicarle información relacionada con la organización de los mismos en caso de que usted desee participar.
                  </li>
                  <li class="mb-2">
                    Adicionalmente, como parte de la operación de BNI QUINTANA ROO, sus datos personales podrán ser utilizados para mantener contacto directo con usted y estar en posibilidad de darle seguimiento continuo y respuesta inmediata en situaciones que así lo ameriten; para informarle puntualmente del estado que guarda su membresía; proporcionarle información relevante respecto de su desempeño como miembro de BNI QUINTANA ROO; para informarle de cambios de cualquier tipo en relación con la metodología o las políticas de BNI QUINTANA ROO; para evaluar su nivel de satisfacción como miembro de BNI QUINTANA ROO; para brindarle el apoyo y los canales de comunicación adecuados para brindarle atención y solución a sus inquietudes y quejas; para proporcionarle capacitación y retroalimentación continua; para proporcionarle información relacionada con BNI QUINTANA ROO que usted desconozca o solicite de manera particular; informarle y mantenerlo actualizado sobre eventos; para proporcionarle información estadística respecto del desempeño de los distintos capítulos de BNI QUINTANA ROO y a nivel mundial; para proporcionarle información respecto de las reuniones de los diversos capítulo de BNI QUINTANA ROO y a nivel mundial.
                  </li>
                  <li class="mb-2">
                    En reconocimiento de la naturaleza de las operaciones de QUINTANA ROO, como una organización de Redes de Negocios, toda la información profesional de los miembros de BNI QUINTANA ROO, por el simple hecho de ser miembros, se podrá utilizar en todo momento y sin necesidad de autorización para efectos de ser proporcionada a otros miembros de BNI QUINTANA ROO con el objeto de dar una referencia o recomendación y propiciar negocios.
                  </li>
                  <li class="mb-2">
                    En relación con la operación diaria de la organización, BNI QUINTANA ROO como cualquier otra empresa hará uso de su información para efectos administrativos, estadísticos, de cobranza y de análisis y planeación estratégica.
                  </li>
                </ul>
                <p class="mt-2 mb-0">
                  En el caso de los empleados de BNI QUINTANA ROO:
                </p>
                <ul>
                  <li>
                    Será utilizada para llevar a cabo el proceso de contratación, integrar su expediente laboral y cumplir con las obligaciones obrero-patronales que deriven de la relación laboral.
                  </li>
                </ul>
              </li>
              <p class="mt-8">
                FINALIDADES SECUNDARIAS
              </p>
              <ol class="ua">
                <li>
                  Invitaciones a eventos y congresos de networking,

                  <p>
                    En caso de que se pretenda tratar los Datos Personales de los usuarios para un fin distinto al expresado en el presente Aviso de Privacidad, BNI QUINTANA ROO, deberá obtener el consentimiento del usuario para tal fin de nueva cuenta.
                  </p>
                </li>
              </ol>
              <li>
                <strong>LOS MECANISMOS PARA QUE EL TITULAR PUEDA MANIFESTAR SU NEGATIVA PARA FINALIDADES SECUNDARIAS O ACCESORIAS</strong>

                <p>
                  En caso de que no desee que sus datos personales se utilicen para llevar a cabo las finalidades expresadas del presente aviso o cualquier otra finalidad que no sea necesaria o que no sea de las que dieron origen a la relación jurídica con el responsable, contará con un plazo de 5 días para que manifieste su negativa a través del correo electrónico: andres.garcia@bnimexico.com, indicando expresamente las finalidades respecto de las cuales no otorga su consentimiento para el tratamiento de datos personales.
                </p>
                <p>
                  La negativa para el uso de sus datos personales para estas finalidades, no podrá ser un motivo para que le neguemos los servicios y productos que solicita o contrata con nosotros.
                </p>
              </li>
              <li>
                <strong>TRATAMIENTO DE DATOS PERSONALES</strong>

                <p>
                  Se considera “Tratamiento de Datos Personales” la obtención, uso, divulgación o almacenamiento de Datos Personales por cualquier medio. El uso se extiende a cualquier actividad de acceso, manejo, aprovechamiento, transferencia o disposición de Datos Personales.
                </p>
                <p>
                  El Tratamiento de Datos Personales por parte de BNI QUINTANA ROO, se sujetará a lo dispuesto en el siguiente punto.
                </p>
              </li>
              <li>
                <strong>ACEPTACIÓN DEL TRATAMIENTO DE DATOS PERSONALES</strong>

                <p>
                  Los datos personales que usted (en lo sucesivo “el Titular”) ha proporcionado directamente o a través de medios electrónicos a BNI QUINTANA ROO, (En lo sucesivo “el Responsable”), han sido recabados y serán tratados por BNI QUINTANA ROO, bajo los principios de licitud, consentimiento, información, calidad, finalidad, lealtad y responsabilidad de conformidad con lo dispuesto por la Ley.
                </p>
              </li>
              <li>
                <strong>TRANSFERENCIAS DE DATOS PERSONALES</strong>

                <p>
                  Por medio del presente se hace de su conocimiento que “EL RESPONSABLE” podrá transferir los datos personales que obren en sus bases de datos a terceras personas para efecto de intercambio de referencias o recomendaciones; también a proveedores de servicios relacionados; y a proveedores de servicios administrativos, contables y de cobranza; y en general a cualquier persona que solicite referencias y/o recomendaciones; lo anterior tomando en cuenta que esta es la naturaleza de la existencia de BNI QUINTANA ROO.
                </p>
                <p>
                  De conformidad con las disposiciones de LA LEY esta transferencia queda limitada a la manifestación de rechazo u oposición por parte de su titular, bajo los términos y requisitos que establece LA LEY. Por lo anterior, hacemos de su conocimiento que la lectura de este párrafo sin que obre respuesta en contrario o su negativa manifiesta, autoriza expresamente a “EL RESPONSABLE” a transferir sus datos de acuerdo con lo especificado.
                </p>
              </li>
              <li>
                <strong>MEDIOS Y PROCEDIMIENTOS PARA EL EJERCICIO DE LOS “DERECHOS ARCO” Y PARA LIMITAR EL USO O DIVULGACIÓN DE LOS DATOS</strong>

                <p>
                  Mediante el presente aviso, “EL RESPONSABLE” le informa que usted tiene derecho a acceder, rectificar y cancelar sus datos personales, así como de oponerse al tratamiento de estos o revocar el consentimiento para el fin que se nos haya otorgado. Para ello, usted puede ponerse en contacto con nosotros en las direcciones, teléfonos y/o correos electrónicos que se mencionan a continuación: <br>
                  Domicilio: Avenida Homero 1933 piso 8 interior 801-2 , Colonia Polanco I Sección, C.P. 11510, Miguel Hidalgo, Ciudad de México, México. <br>
                  Correo electrónico:
                  <strong>
                    <a
                      href="mailto:andres.garcia@bnimexico.com"
                      target="_blank"
                    >
                      andres.garcia@bnimexico.com
                    </a>
                  </strong> <br>
                  Teléfono: 998 7416512
                </p>
                <p>
                  Una vez que se ponga en contacto por alguno de los medios antes mencionados, “EL RESPONSABLE” le proporcionará el formato solicitud de DERECHOS ARCO, el cual deberá ser llenado y entregado por el titular de los datos personales por cualquiera de los medios de contacto ya mencionados.
                </p>
              </li>
              <li>
                <strong>MECANISMOS Y PROCEDIMIENTOS PARA QUE, EN SU CASO, EL TITULAR PUEDA REVOCAR SU CONSENTIMIENTO AL TRATAMIENTO DE SUS DATOS PERSONALES</strong>
                <p>
                  Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. Asimismo, usted deberá considerar que, para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.
                </p>
                <p>
                  Para revocar su consentimiento, tratándose de los miembros y exmiembros y visitantes de BNI MÉXICO, podrán hacerlo directamente a través de la página web bniconnectglobal.com. En el caso de los empleados o proveedores, estos últimos que no sea miembros de BNI MÉXICO, podrán dirigir un correo a bni@bnimexico.com, solicitando dicha revocación.
                </p>
              </li>
              <li>
                <strong>CAMBIOS EN EL AVISO DE PRIVACIDAD</strong>

                <p>
                  “EL RESPONSABLE” se reserva el derecho a periódicamente actualizar este aviso de privacidad. Si se cambia este aviso de privacidad de alguna manera, se publicará un aviso informando sobre el cambio del mismo.
                </p>
                <p>
                  Es responsabilidad del Titular revisar el contenido actualizado del aviso de privacidad que se publique en nuestra página web BNI MÉXICO.
                </p>
                <p>
                  “EL RESPONSABLE” asume que si el Titular no se opone a los cambios informando oportunamente a través de cómo ha quedado precisado en este documento, significa que ha leído, entendido y consentido los términos aquí establecidos.
                </p>
              </li>
              <li>
                <strong>TRATAMIENTO INDEBIDO DE DATOS PERSONALES</strong>

                <p>
                  Si usted como titular considera que su derecho a la protección de sus datos personales ha sido lesionado por alguna conducta u omisión por parte de “EL RESPONSABLE”, o presume alguna violación a las disposiciones previstas en la ley y demás ordenamientos aplicables, podrá interponer su inconformidad o denuncia ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI). Para más información le sugerimos visitar la página web www.inai.org.mx.
                </p>
              </li>
              <li>
                <strong>CONSENTIMIENTO</strong>

                <p>
                  Usted manifiesta que otorga su consentimiento al presente aviso de privacidad, pues ha leído este y conoce las finalidades para el tratamiento de sus datos personales, las transferencias que “EL RESPONSABLE” efectúa, y que consiente libremente el tratamiento de sus datos personales en los términos descritos en el presente aviso de privacidad.
                </p>
                <p>
                  He leído y conozco el Aviso de Privacidad integral y otorgo mi consentimiento a BNI MÉXICO, para que recabe y trate mis datos personales, incluidos aquellos datos considerados sensibles, para las finalidades principales y secundarias a que hace referencia el citado Aviso de Privacidad.
                </p>
              </li>
            </ol>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="dialogpp = false"
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogcookie"
        width="500"
      >
        <v-card>
          <v-card-title class="text-h3 grey lighten-2">
            INFORMACIÓN BÁSICA SOBRE COOKIES
          </v-card-title>

          <v-card-text>
            <p class="text-justify">
              Una cookie o galleta informática es un pequeño archivo de información que se guarda en tu ordenador, "smartphone" o tableta cada vez que visitas nuestra página web.
              Nuestra página web únicamente utiliza cookies técnicas, que son imprescindibles para que la página pueda funcionar. Las tenemos activadas por defecto, pues no necesitan de tu autorización.
              Más información en nuestro apartado POLÍTICA DE COOKIES.
            </p>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="dialogcookie = false"
            >
              ACEPTAR
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogpc"
        width="50%"
      >
        <v-card>
          <v-card-title class="text-h3 grey lighten-2">
            POLÍTICA DE COOKIES
          </v-card-title>

          <v-card-text>
            <p class="text-justify">
              Bienvenida/o a la POLÍTICA DE COOKIES de la página web de la entidad Relaciones Profesionales Estratégicas S.L, provista de NIF B87784153, donde te explicaremos en un lenguaje claro y sencillo todas las cuestiones necesarias para que puedas tener el control sobre ellas en base a tus decisiones personales.
            </p>
            <p class="font-weight-medium">
              ¿QUÉ SON LAS COOKIES Y PARA QUÉ LAS USAMOS?
            </p>
            <p class="text-justify">
              Una cookie o galleta informática es un pequeño archivo de información que se guarda en tu ordenador, “smartphone” o tableta cada vez que visitas nuestra página web.<br>
              En principio, una cookie es inofensiva: no contiene virus, troyanos, gusanos, etc. que puedan dañar tu terminal, pero sí tiene cierto impacto sobre tu derecho a la protección de tus datos personales, pues recoge determinada información concerniente a tu persona (hábitos de navegación, identidad, preferencias, etc.).<br>
              Nuestra página web únicamente utiliza cookies técnicas, las mismas son estrictamente necesarias para que nuestra página web funcione y puedas navegar por la misma. Este tipo de cookies son las que, por ejemplo, nos permiten identificarte, darte acceso a determinadas partes restringidas de la página si fuese necesario, o recordar diferentes opciones o servicios ya seleccionados por ti, como tus preferencias de privacidad. Por ello, están activadas por defecto, no siendo necesaria tu autorización al respecto.<br>
              A través de la configuración de tu navegador, puedes bloquear o alertar de la presencia de este tipo de cookies, si bien dicho bloqueo afectará al correcto funcionamiento de las distintas funcionalidades de nuestra página web.
            </p>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="dialogpc = false"
            >
              ACEPTAR
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-row justify="center">
      <v-col
        cols="12"
        md="auto"
      >
        <v-img
          :width="300"
          src="@/assets/logo.png"
        />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <v-stepper
          v-model="e1"
          elevation="8"
          non-linear
        >
          <v-stepper-header>
            <v-stepper-step
              :complete="e1 > 1"
              step="1"
              :rules="[() => !!stepValid1]"
            >
              Grupo BNI
            </v-stepper-step>

            <v-divider />

            <v-stepper-step
              :complete="e1 > 2"
              step="2"
              :rules="[() => !!stepValid2]"
            >
              Tus datos personales
            </v-stepper-step>

            <v-divider />

            <v-stepper-step
              :complete="e1 > 3"
              step="3"
              :rules="[() => !!stepValid3]"
            >
              Datos de facturación
            </v-stepper-step>

            <v-divider />

            <v-stepper-step
              :complete="e1 > 4"
              step="4"
              :rules="[() => !!stepValid4]"
            >
              Networking
            </v-stepper-step>

            <v-divider />

            <v-stepper-step
              step="5"
              :rules="[() => !!stepValid5]"
            >
              Compromisos
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card
                class="mb-12"
                elevation="2"
                color="grey lighten-4"
              >
                <v-row>
                  <v-col
                    class="mx-auto"
                    cols="11"
                    md="11"
                  >
                    <v-form
                      ref="stepForm1"
                      v-model="stepValid1"
                      lazy-validation
                    >
                      <v-radio-group
                        v-model="esRenovacion"
                        mandatory
                        row
                      >
                        <v-radio
                          label="ALTA NUEVA"
                          :value="false"
                        />
                        <v-radio
                          label="RENOVACIÓN"
                          :value="true"
                        />
                      </v-radio-group>
                      <v-select
                        v-model="memberItem.grupo"
                        class="mt-3"
                        outlined
                        :rules="campoRules"
                        :items="grupoBNI"
                        item-text="grupo"
                        item-value="id"
                        label="GRUPO BNI"
                        placeholder="GRUPO BNI"
                      />
                      <v-text-field
                        v-if="esRenovacion"
                        v-model="memberItem.mail"
                        outlined
                        :rules="emailRules"
                        label="EMAIL"
                        placeholder="EMAIL"
                        hint="Introduce por favor el correo con el que te diste de alta en BNI Connect para poder acceder a tus datos."
                      />
                    </v-form>
                  </v-col>
                </v-row>
              </v-card>

              <v-btn
                color="primary"
                :disabled="!stepValid1"
                @click="validate1"
              >
                Siguiente
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card
                class="mb-12"
                elevation="2"
                color="grey lighten-4"
              >
                <v-row>
                  <v-col
                    class="mx-auto"
                    cols="11"
                    md="11"
                  >
                    <v-form
                      ref="stepForm2"
                      v-model="stepValid2"
                      lazy-validation
                    >
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model.trim="memberItem.nombre"
                            outlined
                            :rules="campoRules"
                            label="NOMBRE"
                            placeholder="NOMBRE"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model.trim="memberItem.apellido"
                            outlined
                            :rules="campoRules"
                            label="APELLIDOS"
                            placeholder="APELLIDOS"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model.trim="memberItem.mail"
                            :disabled="esRenovacion"
                            outlined
                            :rules="emailRules"
                            label="EMAIL"
                            placeholder="EMAIL"
                            hint="Introduce por favor el correo con el que te diste de alta en BNI Connect para poder acceder a tus datos."
                            @keydown.space.prevent
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <!-- <v-text-field
                            v-model.trim="memberItem.telefono"
                            outlined
                            :rules="telefonoRules"
                            label="TELÉFONO"
                            placeholder="TELÉFONO"
                            @keydown.space.prevent
                            @keypress="keyboardKeysAllowedForTelephone"
                          /> -->
                          <vue-tel-input-vuetify
                            v-model.trim="memberItem.telefono"
                            default-country="MX"
                            :max-len="30"
                            mode="international"
                            clearable
                            outlined
                            label="TELÉFONO"
                            placeholder="TELÉFONO"
                            :rules="telefonoRules"
                            :error-messages="telephoneErrorMessage"
                            @keydown.space.prevent
                            @keydown="keyboardKeysAllowedForTelephone"
                            @input="isValidTelephone"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model.trim="memberItem.especialidad"
                            outlined
                            :rules="campoRules"
                            label="GIRO O ESPECIALIDAD A REPRESENTAR EN BNI"
                            placeholder="GIRO O ESPECIALIDAD A REPRESENTAR EN BNI"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model.trim="memberItem.invito"
                            outlined
                            :rules="campoRules"
                            label="NOMBRE Y APELLIDO DE QUIEN TE INVITÓ"
                            placeholder="NOMBRE Y APELLIDO DE QUIEN TE INVITÓ"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
              </v-card>

              <v-btn
                @click="e1 = 1"
              >
                Atrás
              </v-btn>

              <v-btn
                color="primary"
                :disabled="!stepValid2"
                @click="validate2"
              >
                Siguiente
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card
                class="mb-12"
                elevation="2"
                color="grey lighten-4"
              >
                <v-row>
                  <v-col
                    class="mx-auto"
                    cols="11"
                    md="11"
                  >
                    <v-form
                      ref="stepForm3"
                      v-model="stepValid3"
                      lazy-validation
                    >
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model.trim="memberItem.razon"
                            outlined
                            :rules="campoRules"
                            label="RAZON SOCIAL / NOMBRE FISCAL"
                            placeholder="RAZON SOCIAL / NOMBRE FISCAL"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model.trim="memberItem.cif"
                            :maxlength="20"
                            outlined
                            :rules="campoRules"
                            label="RFC"
                            placeholder="RFC"
                            @keydown.space.prevent
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model.trim="memberItem.domicilio"
                            outlined
                            :rules="campoRules"
                            label="DIRECCIÓN FISCAL"
                            placeholder="DIRECCIÓN FISCAL"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model.trim="memberItem.poblacion"
                            outlined
                            :rules="campoRules"
                            label="CIUDAD"
                            placeholder="CIUDAD"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model.trim="memberItem.provincia"
                            outlined
                            :rules="campoRules"
                            label="ESTADO"
                            placeholder="ESTADO"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model.trim="memberItem.postal"
                            type="number"
                            outlined
                            :rules="postalRules"
                            label="CÓDIGO POSTAL"
                            placeholder="CÓDIGO POSTAL"
                            @keydown.space.prevent
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
              </v-card>

              <v-btn
                @click="e1 = 2"
              >
                Atrás
              </v-btn>

              <v-btn
                color="primary"
                :disabled="!stepValid3"
                @click="validate3"
              >
                Siguiente
              </v-btn>
            </v-stepper-content>
            <v-stepper-content step="4">
              <v-card
                class="mb-12"
                elevation="2"
                color="grey lighten-4"
              >
                <v-row>
                  <v-col
                    class="mx-auto"
                    cols="11"
                    md="11"
                  >
                    <v-form
                      ref="stepForm4"
                      v-model="stepValid4"
                      lazy-validation
                    >
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-select
                            v-model="memberItem.miembroBNI"
                            outlined
                            :items="grupo"
                            :rules="campoRules"
                            item-text="text"
                            item-value="value"
                            label="¿Ha sido miembro de BNI anteriormente?"
                            placeholder="¿Ha sido miembro de BNI anteriormente?"
                            hint="¿Ha sido miembro de BNI anteriormente?"
                            @change="validateGrupoBNIAnt()"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model.trim="memberItem.grupoBNIAnt"
                            outlined
                            :rules="grupoBNIAntRules"
                            label="En caso afirmativo, ¿cuándo y en qué grupo?"
                            placeholder="En caso afirmativo, ¿cuándo y en qué grupo?"
                            hint="En caso afirmativo, ¿cuándo y en qué grupo?"
                            @input="validateGrupoBNIAnt()"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-select
                            v-model="memberItem.miembroOrgQ"
                            outlined
                            :items="grupo"
                            :rules="campoRules"
                            item-text="text"
                            item-value="value"
                            label="¿Es miembro actualmente de alguna otra organización de networking cuyo objetivo sea también el intercambio de referencias?"
                            placeholder="¿Es miembro actualmente de alguna otra organización de networking cuyo objetivo sea también el intercambio de referencias?"
                            hint="¿Es miembro actualmente de alguna otra organización de networking cuyo objetivo sea también el intercambio de referencias?"
                            @change="validateMiembroOrgA()"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model.trim="memberItem.miembroOrgA"
                            outlined
                            :rules="miembroOrgARules"
                            label="En caso afirmativo, ¿a cuál?"
                            placeholder="En caso afirmativo, ¿a cuál?"
                            hint="En caso afirmativo, ¿a cuál?"
                            @input="validateMiembroOrgA()"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
              </v-card>

              <v-btn
                @click="e1 = 3"
              >
                Atrás
              </v-btn>

              <v-btn
                color="primary"
                :disabled="!stepValid4"
                @click="validate4"
              >
                Siguiente
              </v-btn>
            </v-stepper-content>
            <v-stepper-content step="5">
              <v-card
                class="mb-12"
                elevation="2"
                color="grey lighten-4"
              >
                <v-row>
                  <v-col
                    class="mx-auto"
                    cols="11"
                    md="11"
                  >
                    <v-form
                      ref="stepForm5"
                      v-model="stepValid5"
                      lazy-validation
                    >
                      <p>
                        Certifico que toda la información contenida en este formulario es veraz y que, de lo contrario, puede suponer la terminación de mi participación en el Grupo a discreción de BNI.
                        También entiendo que, una vez aceptada mi candidatura, las cuotas no son reembolsables.<b> (Firmar en el campo táctil o con el ratón)</b>
                      </p>
                      <v-dialog
                        v-model="dialog"
                        persistent
                        scrollable
                        max-width="800"
                      >
                        <v-card>
                          <v-card-text>
                            <br>
                            <h2 class="text-center mb-2">
                              <b>Código de Ética</b>
                            </h2>
                            1. Proveeré servicios de calidad al precio presupuestado.<br>
                            2. Seré sincero con los miembros y sus referencias.<br>
                            3. Fomentaré la buena voluntad y la confianza entre los miembros y sus referencias.<br>
                            4. Me comprometo a hacer seguimiento de las referencias recibidas.<br>
                            5. Mostraré una actitud positiva y solidaria.<br>
                            <br>
                            <h2 class="text-center mb-2">
                              <b>Políticas Generales BNI</b>
                            </h2>
                            <p class="mb-3">
                              El Comité de Membresías de cada Capítulo tiene la autoridad final con respecto a las Políticas de BNI®. El Comité de Membresías puede asignar a un Miembro de BNI® a un Período de Apoyo o declarar abierta la categoría profesional de un Miembro en caso de incumplimiento de las Políticas y/o del Código de Ética o Valores Fundamentales de BNI®.
                            </p>
                            1. Sólo puede unirse a un Capítulo de BNI® una persona por cada categoría profesional, y cada Miembro solo puede representar una categoría en su Capítulo de BNI®.<br>
                            2. Los Miembros de BNI® deben representar su actividad principal.<br>
                            3. Los Miembros de BNI® han de llegar puntuales y quedarse durante toda la reunión.<br>
                            4. Una persona solo puede ser Miembro de un Capítulo de BNI®. Un Miembro no debe participar en ningún otro programa cuyo propósito principal sea el intercambio de referencias.<br>
                            5. Un Miembro de BNI® tiene permitidas tres ausencias dentro de un período continuo de seis meses. Si un Miembro no puede asistir, puede enviar un sustituto; esto no contará como ausencia.<br>
                            6. Se espera que los Miembros se comprometan con su Capítulo de BNI® aportando referencias cualificadas y/o Invitados.<br>
                            7. Los Invitados pueden asistir a las reuniones de BNI® hasta en dos ocasiones.<br>
                            8. Solo los Miembros de BNI® que hayan completado el “Programa de Éxito para Miembros”, Directores y Directores Consultores pueden hacer las presentaciones durante las reuniones de BNI®.<br>
                            9. Los permisos de ausencia son posibles para ciertas circunstancias atenuantes (por ejemplo, un problema médico prolongado que impide que el miembro trabaje) a discreción del Comité de Membresías.<br>
                            10. Los Miembros que deseen cambiar su categoría en BNI® deben presentar una nueva candidatura al Comité de Membresías para su aprobación.<br>
                            11. Cualquier listado de Miembros de BNI® tiene el único propósito de generar referencias. Antes de enviar cualquier comunicación comercial o de marketing a los Miembros de BNI® que no sean de tu Capítulo, o a Directores, el destinatario debe dar su consentimiento. El consentimiento debe ser libre, específico, informado y sin ambigüedades.<br>
                            <p class="mt-3">
                              Las políticas están sujetas a cambios. Cualquier propuesta de cambio en las políticas ha de ser revisada previamente por la Junta Internacional de Consejeros.
                            </p>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer />
                            <v-btn
                              text
                              @click="dialog = false"
                            >
                              Cerrar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-checkbox
                        v-model="checkbox"
                        :rules="[v => !!v || 'No olvides leer y aceptar las Políticas']"
                        required
                      >
                        <template v-slot:label>
                          <div>
                            Acepto y me comprometo a seguir la
                            <a
                              href="#"
                              @click="dialog = true"
                            >
                              Política General y el Código de Ética de BNI
                            </a>
                          </div>
                        </template>
                      </v-checkbox>
                      <v-checkbox
                        :rules="[v => !!v || 'Debes leer y aceptar las políticas de privacidad']"
                        required
                      >
                        <template v-slot:label>
                          <div>
                            Consiento el uso de mis datos para los fines indicados en el
                            <a
                              href="#"
                              @click="dialogpp = true"
                            >
                              aviso de privacidad
                            </a>
                          </div>
                        </template>
                      </v-checkbox>
                      <v-row>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <VueSignaturePad
                            ref="signaturePad"
                            width="60%"
                            height="200px"
                            :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()}}"
                            :custom-style="estilacho"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="4"
                          class="text-center"
                        >
                          <v-btn
                            color="red"
                            class="mr-2"
                            @click="undo"
                          >
                            Deshacer firma
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
              </v-card>

              <v-btn
                @click="e1 = 4"
              >
                Atrás
              </v-btn>
              <v-btn
                color="primary"
                :disabled="!stepValid5"
                @click="validate5"
              >
                Siguiente
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
    <!-- <v-row justify="center">
      <v-col
        class="text-center mb-sm-0 mb-5"
        cols="auto"
      >
        <p class="mb-2">
          Si ya eres un miembro registado y necesitas renovar tu membresía. Haz clic aquí
        </p>
        <v-btn
          color="primary"
          @click="irRenovaciones()"
        >
          Renovar membresía
        </v-btn>
      </v-col>
    </v-row> -->
    <v-row justify="center">
      <v-col
        class="text-center mb-sm-0 mb-5"
        cols="auto"
      >
        <a
          href="#"
          class="mr-0 grey--text text--darken-3"
          rel="noopener"
          @click="dialogpp = true"
        >
          Aviso de privacidad
        </a>
      </v-col>
      <v-col
        class="text-center mb-sm-0 mb-5"
        cols="auto"
      >
        <a
          href="#"
          class="mr-0 grey--text text--darken-3"
          rel="noopener"
          @click="dialogpc = true"
        >
          Política de cookies
        </a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import GruposApi from '@/services/api/Grupos'
  import MembersApi from '@/services/api/Miembros'

  export default {
    name: 'RegisterMember',
    data: () => ({
      e1: 1,
      dialog: false,
      dialogpp: false,
      dialogcookie: true,
      dialogpc: false,
      checkbox: false,
      valid: true,
      lazy: false,
      snackbar: false,
      colorSnak: 'info',
      text: '',
      timeout: 3000,
      showSignature: true,
      estilacho: {
        border: 'black 3px solid',
        'margin-right': 'auto',
        'margin-left': 'auto',
      },
      stepValid1: true,
      stepValid2: true,
      stepValid3: true,
      stepValid4: true,
      stepValid5: true,
      miembroANterior: true,
      stepForm: [
        'grupo',
      ],
      memberItem: {
        grupo: '',
        nombre: '',
        apellido: '',
        mail: '',
        telefono: null,
        especialidad: '',
        invito: '',
        razon: '',
        cif: '',
        domicilio: '',
        poblacion: '',
        provincia: '',
        postal: null,
        miembroBNI: '',
        grupoBNIAnt: '',
        miembroOrgQ: '',
        miembroOrgA: '',
        esInvitacion: false,
        estado: '',
      },
      grupo: [
        { value: 'SI', text: 'SI' },
        { value: 'NO', text: 'NO' },
      ],
      esRenovacion: null,
      grupoBNI: [],
      nameRules: [
        v => !!v || 'Completa este campo, por favor',
      ],
      apeRules: [
        v => !!v || 'Completa este campo, por favor',
      ],
      emailRules: [
        v => !!v || 'Completa este campo, por favor',
        v => /.+@.+\..+/.test(v) || 'Ingresa un correo electrónico correcto',
      ],
      telefonoRules: [
        v => !!v || 'Completa este campo, por favor',
        v => /^.{7,30}$/.test(v) || 'Ingrese un número telefónico correcto',
      ],
      postalRules: [
        v => !!v || 'Completa este campo, por favor',
        v => /^[0-9]{4,5}$/.test(v) || 'Ingrese un código postal correcto',
      ],
      ciudadRules: [
        v => !!v || 'Completa este campo, por favor',
      ],
      campoRules: [
        v => !!v || 'Completa este campo, por favor',
      ],
      grupoBNIAntRules: [true],
      miembroOrgARules: [true],
      telephoneErrorMessage: null,
    }),

    created () {
      // Hay token
      if (undefined !== this.$route.params.token) {
        this.fetchMiembro(this.$route.params.token)
      }
      this.initialize()
    },

    methods: {
      initialize () {
        this.fetchGrupos()
      },
      fetchMiembro (token) {
        MembersApi.getMiembroByToken(token)
          .then(miembro => {
            if (miembro.estado === 'SUSCRITO' || parseInt(miembro.eliminado) === 1) {
              this.$router.push({ name: 'Member Register' })
            } else {
              // El usuario invitado que presione el botón de Siguiente, se considerará ya luego como registrado
              miembro.esInvitacion = false
              this.memberItem = Object.assign({}, miembro)
            }
          })
          .catch(error => {
            console.log(error)
            this.$router.push({ name: 'Member Register' })
          })
      },
      fetchGrupos () {
        GruposApi.getGruposActivosNoRenovaciones()
          .then(grupo => {
            this.grupoBNI = grupo
          })
          .catch(error => console.log(error))
      },
      isValidTelephone (value, detail) {
        // console.log(detail.isValid)
        if (detail.isValid) {
          this.telephoneErrorMessage = null
        } else {
          this.telephoneErrorMessage = 'El formato no coincide con el país seleccionado'
        }
      },
      keyboardKeysAllowedForTelephone ($event) {
        // Basado en: Key values for keyboard events
        // console.log($event.key)
        const charsAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '+', 'Backspace', 'ArrowRight', 'ArrowLeft', 'Delete']
        if (!charsAllowed.includes($event.key)) {
          $event.preventDefault()
        }
      },
      undo () {
        this.$refs.signaturePad.undoSignature()
      },
      validate1 () {
        this.stepValid1 = false
        const v = this.$refs.stepForm1.validate()
        if (v && !this.esRenovacion) {
          this.stepValid1 = true
          this.e1 = 2
        }
        if (v && this.esRenovacion) {
          MembersApi.getMiembroByEmail(this.memberItem.mail)
            .then(miembro => {
              if (parseInt(miembro.eliminado) === 1) {
                this.text = 'Su usuario ha sido inhabilitado. Puede ponerse en contacto si considera que esto ha sido un error.'
                this.colorSnak = 'error'
                this.snackbar = true
              } else if (typeof this.memberItem.grupo === 'number' && (miembro.grupo.id !== this.memberItem.grupo)) {
                this.text = 'Usted no es miembro del grupo que ha seleccionado.'
                this.colorSnak = 'error'
                this.snackbar = true
              } else if (typeof this.memberItem.grupo !== 'number' && (miembro.grupo.id !== this.memberItem.grupo.id)) {
                this.text = 'Usted no es miembro del grupo que ha seleccionado.'
                this.colorSnak = 'error'
                this.snackbar = true
              } else {
                const grupoSeleccionado = this.memberItem.grupo
                this.memberItem = Object.assign({}, miembro)
                this.memberItem.grupo = grupoSeleccionado
                this.memberItem.esInvitacion = false
                this.stepValid1 = true
                this.e1 = 2
              }
            })
            .catch(error => {
              console.log(error)
              this.text = 'No hemos encontrado su dirección de correo en nuestros registros.'
              this.colorSnak = 'error'
              this.snackbar = true
            })
        }
      },
      validate2 () {
        this.stepValid2 = false
        const v = this.$refs.stepForm2.validate()
        if (v) {
          if (undefined === this.$route.params.token && !this.esRenovacion) {
            MembersApi.buscarEmailDuplicado(this.memberItem.mail)
              .then(response => {
                this.stepValid2 = true
                this.e1 = 3
              })
              .catch(error => {
                console.log(error)
                this.text = 'Esta dirección de correo ya existe. Si necesita renovar membresía, presione el botón Atrás y marque esa opción.'
                this.colorSnak = 'error'
                this.snackbar = true
              })
          } else {
            this.stepValid2 = true
            this.e1 = 3
          }
        }
      },
      validate3 () {
        this.stepValid3 = false
        const v = this.$refs.stepForm3.validate()
        if (v) {
          this.stepValid3 = true
          this.e1 = 4
        }
      },
      validate4 () {
        this.stepValid4 = false
        const v = this.$refs.stepForm4.validate()
        if (v) {
          this.stepValid4 = true
          this.e1 = 5
        }
      },
      validate5 () {
        this.stepValid5 = false
        const v = this.$refs.stepForm5.validate()
        if (v) {
          this.stepValid5 = true
          if (undefined !== this.$route.params.token && !this.esRenovacion) {
            MembersApi.update(this.memberItem.id, this.memberItem)
              .then(response => {
                this.$router.push({ name: 'memberResume', params: { token: response.suscripcion } })
              })
              .catch(error => {
                console.log(error)
                this.text = 'No ha sido posible completar el registro. Es posible que algo no esté bien con sus datos'
                this.colorSnak = 'error'
                this.snackbar = true
              })
          } else if (this.esRenovacion) {
            // Se agrega acá este valor, solo con el fin de envío al backend. No aplica en el registro de miembro
            this.memberItem.esRenovacion = true
            MembersApi.addRenewal(this.memberItem)
              .then(response => {
                this.$router.push({ name: 'memberResume', params: { token: response.suscripcion } })
              })
              .catch(error => {
                console.log(error)
                this.text = 'No ha sido posible completar el registro. Es posible que algo no esté bien con sus datos'
                this.colorSnak = 'error'
                this.snackbar = true
              })
          } else {
            MembersApi.add(this.memberItem)
              .then(response => {
                this.$router.push({ name: 'memberResume', params: { token: response.suscripcion } })
              })
              .catch(error => {
                console.log(error)
                this.text = 'No ha sido posible completar el registro. Es posible que algo no esté bien con sus datos'
                this.colorSnak = 'error'
                this.snackbar = true
              })
          }
        }
      },
      validateGrupoBNIAnt () {
        if (this.memberItem.miembroBNI === 'SI' && this.memberItem.grupoBNIAnt === '') {
          this.grupoBNIAntRules = ['Completa este campo, por favor']
        } else {
          this.grupoBNIAntRules = [true]
        }
      },
      validateMiembroOrgA () {
        if (this.memberItem.miembroOrgQ === 'SI' && this.memberItem.miembroOrgA === '') {
          this.miembroOrgARules = ['Completa este campo, por favor']
        } else {
          this.miembroOrgARules = [true]
        }
      },
      irRenovaciones () {
        this.$router.push({ name: 'Member Renewal' })
      },
    },
  }
</script>

<style scoped>
ol.la {list-style-type: lower-alpha;}
ol.ua {list-style-type: upper-alpha;}
ol.ur {list-style-type: upper-roman;}
ul {list-style-type:disc;}
</style>
